import React, { useEffect, useState } from 'react';
import TextGroup, { CTA } from '../Texts'

import audit2 from '../../assets/images/audit/audit-2.png'
import ServiceModal from "../ServiceModal";
import close_icon from '../../assets/images/close.svg'

const SecurityTwo = ({ lang }) => {
  const isEn = lang === 'en';
  const [clickedTileIndexes, setClickedTileIndexes] = useState([]);

  const [modalData, setModalData] = useState();
  const [modalVisible, setModalVisible] = useState(false);

  const handleTileClick = (index) => {

    setClickedTileIndexes((prevIndexes) => {
      if (prevIndexes.includes(index)) {
        return prevIndexes.filter((id) => id !== index);
      } else {
        return [...prevIndexes, index];
      }
    });

    //show modal
    setModalVisible(true);
    // set modal data on click
    setModalData(sliderTiles[index]);
  };
    

   // hide modal on close.
   const onModalCloseHandler = () => {
    setModalVisible(false);
    setClickedTileIndexes([]);
  };
    

  const sliderTiles = [
    {
      rootProps: { className: 'tile' },
      title: {
        type: 'h3',
        text: isEn ? 'Atualizações do WordPress, temas e plugins' : 'Atualizações do WordPress, temas e plugins',
      },
      modal: {
        title:"Atualizações do WordPress, temas e plugins",
        description: "Garantimos uma segurança contínua com atualizações regulares, mantendo o seu site resistente contra ameaças.",
      }
    },
    {
      rootProps: { className: 'tile' },
      title: {
        type: 'h3',
        text: isEn ? 'Segurança do Servidor' : 'Segurança do Servidor',
      },
      modal: {
        title:"Segurança do Servidor",
        description: "Reforçamos as defesas do seu servidor, protegendo dados críticos e garantindo a continuidade do seu negócio.",
      }
    },
    {
      rootProps: { className: 'tile' },
      title: {
        type: 'h3',
        text: isEn ? 'Autenticação e Senhas' : 'Autenticação e Senhas',
      },
      modal: {
        title:"Autenticação e Senhas",
        description: "Maximizamos a segurança de entrada, autorizando apenas utilizadores legítimos e protegendo a integridade dos seus dados.",
      }
    },
    {
      rootProps: { className: 'tile' },
      title: {
        type: 'h3',
        text: isEn ? 'Permissões de Ficheiros e Diretórios' : 'Permissões de Ficheiros e Diretórios',
      },
      modal: {
        title:"Permissões de Ficheiros e Diretórios",
        description: "Controlamos as permissões, para que apenas quem tiver autorização tenha acesso aos seus ficheiros, fortalecendo a segurança online.",
      }
    },
    {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Análise ao código' : 'Análise ao código',
        },
        modal: {
          title:"Análise ao código",
          description: "Examinamos o código para detetar e corrigir vulnerabilidades, mantendo a resistência e segurança do sistema.",
        }
       
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Backup e Recuperação' : 'Backup e Recuperação',
        },
        modal: {
          title:"Backup e Recuperação",
          description: "Salvaguardamos dados críticos, garantindo uma rápida recuperação em situações adversas, minimizando impactos negativos.",
        }
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Proteção contra Brute Force' : 'Proteção contra Brute Force',
        },
        modal: {
          title:"Proteção contra Brute Force",
          description: "Reforçamos contra acessos não autorizados, fortalecendo as barreiras e garantindo a segurança dos seus dados.",
        }
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Monitorização de Atividade' : 'Monitorização de Atividade',
        },
       
        modal: {
          title:"Monitorização de Atividade",
          description: "Mantemos uma vigilância constante, identificando padrões de atividade suspeitos, para agir preventivamente contra potenciais ameaças.",
        }
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Auditoria de Plugins e Temas' : 'Auditoria de Plugins e Temas',
        },
       
        modal: {
          title:"Auditoria de Plugins e Temas",
          description: "Garantimos segurança, avaliando e selecionando componentes para prevenir fragilidades e proteger o seu site.",
        }
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Segurança de Terceiros' : 'Segurança de Terceiros',
        },
       
        modal: {
          title:"Segurança de Terceiros",
          description: "Avaliamos parceiros e fornecedores rigorosamente, assegurando contribuições positivas para a segurança global.",
        }
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Correções e Ações' : 'Correções e Ações',
        },
       
        modal: {
          title:"Correções e Ações",
          description: "Agimos proativamente, mantendo a segurança no mais alto nível e respondendo rapidamente a qualquer potencial ameaça.",
        }
      },
  ]

  return (
    <section id="services-two" className="bg-white services lp-tiles audit-two">
      <div className="content container">
        <div className="col-12 slider-wrapper">
          <TextGroup
            rootProps={{
              className: 'col-12 heading-large',
            }}
            title={{
              type: 'h2',
              text: isEn
                ? 'Serviços log segurança:'
                : 'Serviços log segurança:',
            }}
          />
          <div className="audit2-cnt">
            <div className="row tiles-row row-relative">
              {sliderTiles.map((tile, i) => (
                <div
                  key={`services-column-${i}`}
                  className={`col-4 col-12-medium tile-column ${
                    clickedTileIndexes.includes(i) ? 'clicked' : ''
                  }`}
                  onClick={() => handleTileClick(i)}
                >
                  <TextGroup {...tile} />
                </div>
              ))}
            </div>
            <div className="cta cta-audit">
              <CTA
                rootProps={{
                  className: 'button primary audit-anchor-link gethelp-two',
                  to: isEn ? '#' : '#',
                }}
                text={isEn ? "Let's talk" : 'Obtenha a nossa ajuda'}
              />
            </div>
          </div>
        </div>
      </div>

      <ServiceModal
        visible={modalVisible}
        title={modalData?.modal?.title}
        description={modalData?.modal?.description}
      >
        <img 
          src={close_icon} 
          alt="Close" 
          className="service-modal__close"
          onClick={onModalCloseHandler}
        />
          <a 
            href="#" 
            className="button audit-btn audit-anchor-link gethelp-box"
            onClick={onModalCloseHandler}
          >
           {isEn ? "Schedule a Free Consultation" : 'Obtenha a nossa ajuda'}
          </a>
        </ServiceModal>
    </section>
  )
}

export default SecurityTwo
