import React from 'react'
import _ from 'lodash'

import introimg from '../../assets/images/audit/intro/security-intro.png'

import TextGroup, { Image } from '../Texts'

const SecurityIntro = ({ lang }) => {
  const isEn = lang === 'en'

  return (
    <section id="performance-intro" className="bg-white bg-gray lp-intro">
      <div className="content container">
        <div className="row">
            <div className="tile row">
                <div className="col-6 col-12-medium image image-margin-right">
                <Image image={introimg} loading="lazy" />
                </div>
                <div className="text-group col-6 col-12-medium">
                    <h2>Serviços de Segurança</h2>
                    <p>A segurança do seu website WordPress é essencial para a solidez do seu negócio. Na log, dedicamo-nos a garantir a segurança contínua da sua presença online, protegendo-a de ameaças cibernéticas.</p>
                    <ul>
                        <li>Protegemos e corrigimos potenciais problemas de segurança</li>
                        <li>Garantimos a segurança inabalável do seu site</li>
                        <li>Preservamos a confidencialidade do seu negócio</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    </section>
  )
}
export default SecurityIntro
