import React, { useEffect } from 'react';

import Layout from '../components/layout'
import SecurityOne from '../components/security/SecurityOne'
import SecurityTwo from '../components/security/SecurityTwo'
import SecurityThree from '../components/security/SecurityThree'
import SecurityFour from '../components/security/SecurityFour'
import SecurityFive from '../components/security/SecurityFive'
import SecurityIntro from '../components/security/SecurityIntro'
import AuditCta from '../components/audit/AuditCta'

const Security = props => {
    useEffect(() => {
        window.scrollTo(0, 0);

        // Find all elements with the specified class
        const ctaElements = document.querySelectorAll('.audit-anchor-link'); // Replace with your actual class name
    
        // Add click event listener to each element
        ctaElements.forEach(element => {
          element.addEventListener('click', handleCTAClick);
        });
    
        // Define the click event handler
        function handleCTAClick(event) {
          event.preventDefault();
    
          const meetingsContainer = document.querySelector('.meetings-iframe-container'); // Get the meetings container
          if (meetingsContainer) {
            meetingsContainer.classList.add('visible'); // Show the meetings container
    
            // Scroll to the meetings container after a short delay
            setTimeout(() => {
              meetingsContainer.scrollIntoView({ behavior: 'smooth' });
            }, 100);
    
            // Add the script to create the iframe
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
            script.async = true;
            meetingsContainer.appendChild(script);
          }
        }
    
        // Cleanup: Remove event listeners when the component unmounts
        return () => {
          ctaElements.forEach(element => {
            element.removeEventListener('click', handleCTAClick);
          });
        };
      }, []);

      return (
    <Layout
      lang='pt'
      title="Proteja o seu site WordPress e o seu negócio!"
      meta={{
        description: 'Proteja o seu site WordPress e os dados dos seus clientes contra ciber ataques com a ajuda da log.',
      }}
      rootProps={{ className: 'page work audit-page bg-blue' }}
      headerProps={{ isBlue: true }}
    >
      <SecurityOne lang="pt" />
      <SecurityIntro lang="pt" />
      <SecurityTwo lang="pt" />
      <SecurityFour lang="pt" />
      <SecurityThree lang="pt" />
      <AuditCta lang="pt" />
      <SecurityFive lang="pt" />
    </Layout>
  );
};

export default Security
